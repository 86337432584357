import React from 'react'
import { List, ListItem } from '../ui'
import EventDuration from './EventDuration'
import EventLocation from './EventLocation'
import { PostPreviewWide } from '../posts'

export default function EventList({ events, titleAs }) {
  return (
    <List mx={[0, -2]}>
      {events.map(({
        startDate, endDate, startTime, endTime, allDay, location, ...event
      }) => (
        <ListItem
          key={event.uri}
          width={1}
          px={[0, 2]}
          pb={4}
        >
          <PostPreviewWide
            {...event}
            titleAs={titleAs}
            meta={(
              <>
                <EventDuration
                  startDate={startDate}
                  endDate={endDate}
                  startTime={startTime}
                  endTime={endTime}
                  isAllDay={allDay}
                />
                <br />
                <EventLocation {...location} />
              </>
            )}
          />
        </ListItem>
      ))}
    </List>
  )
}
