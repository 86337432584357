import React from 'react'
import styled from '@emotion/styled'
import {
  Flex, Box, Heading, Text, Link,
} from '../ui'
import PostExcerpt from './PostExcerpt'
import PostThumbnailRemote from './PostThumbnailRemote'

const BlockLink = styled(Link)`
  display: block;
`

const PostPreviewWide = ({
  uri, title, meta = '', excerpt = '', featuredImage, titleAs = 'h2',
}) => (
  <article>
    <BlockLink to={uri}>
      <Flex flexDirection={['column', 'row']}>
        <Box
          width={325}
          maxWidth="100%"
          minWidth={['initial', 325]}
        >
          <PostThumbnailRemote
            alt={title}
            maxHeight={200}
            maxWidth={325}
            {...featuredImage.node}
            {...featuredImage.node.mediaDetails}
          />
        </Box>
        <Box
          ml={[0, 3]}
          mt={[2, 0]}
          minHeight={[80, 120]}
        >
          <Heading
            as={titleAs}
            fontSize={3}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Text
            mt={1}
            variant="description"
          >
            {meta}
          </Text>
          <PostExcerpt my={3}>
            {excerpt ? excerpt.replace(/<[^>]+>/g, '') : ''}
          </PostExcerpt>
        </Box>
      </Flex>
    </BlockLink>
  </article>
)

export default PostPreviewWide
