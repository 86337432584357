import React from 'react'

const EventLocation = ({ title, town, address }) => (
  <span
    // eslint-disable-next-line
    dangerouslySetInnerHTML={{
      __html: [title, town, address].filter((v) => !!v).join(', '),
    }}
  />
)

export default EventLocation
